<template>
  <div>
    <KTCard title="Company User Approval List">
      <template v-slot:body>
        <b-row v-if="!!tableSettings">
          <b-col cols="6">
            <b-form-group class="m-2" label-size="sm">
              <template v-if="tableSettings.refresh">
                <b-button size="sm" class="mr-2">
                  <i class="fa fa-sync"></i> Refresh
                </b-button>
              </template>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="m-2"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
            >
              <template v-if="tableSettings.filter">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-form-group>
          </b-col>
        </b-row>

        <b-table
          :per-page="tableSettings.perPage"
          :current-page="tableSettings.currentPage"
          :filter="filter"
          striped
          bordered
          hover
          show-empty
          :items="tableItems"
          :fields="fields"
        >
          <template v-slot:cell(actions)="row">
            <b-button-group size="sm">
              <b-button
                @click="onApprove(row.item.ref_id)"
                variant="primary"
                size="sm"
              >
                Approve
              </b-button>
              <b-button
                @click="onReject(row.item.ref_id)"
                variant="danger"
                size="sm"
              >
                REJECT
              </b-button>
            </b-button-group>
          </template>
        </b-table>

        <b-pagination
          v-if="tableSettings.row > tableSettings.perPage"
          class="pl-0"
          v-model="currentPage"
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
        ></b-pagination>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import {
  APPROVE_COMPANY_USER_REGISTRATION,
  GET_COMPANY_USER_PRE_REG_APPROVAL_LIST,
  REJECT_COMPANY_USER_REGISTRATION,
} from "@/core/services/store/modules/globaladmin/administration.module";

export default {
  name: "blank",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Global Admin" },
      { title: "Approval" },
    ]);

    let payload = {
      ...this.defaultPayload,
      q: "PENDING", //PENDING | APPROVED_HISTORY_LIST | REJECTED
    };
    this.$store.dispatch(GET_COMPANY_USER_PRE_REG_APPROVAL_LIST, payload);
  },
  computed: {
    tableSettings() {
      const list =
        this.$store.state.globaladmin_administration
          .companyUserPreRegApprovalList;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        refresh: false,
        filter: true,
      };
    },
    tableItems() {
      const list =
        this.$store.state.globaladmin_administration
          .companyUserPreRegApprovalList;
      if (list && list.hasOwnProperty("data")) {
        return list.data;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      filter: "",
      currentPage: 1,
      fields: [
        {
          key: "ref_id",
          label: "Ref Id",
          sortable: true,
        },
        {
          key: "company_name",
          label: "Company Name",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },

  methods: {
    onApprove(ref_id) {
      console.log("Approve");
      let payload = {
        ref_id: ref_id,
      };
      this.$store.dispatch(APPROVE_COMPANY_USER_REGISTRATION, payload);
    },
    onReject(ref_id) {
      let payload = {
        ref_id: ref_id,
      };
      this.$store.dispatch(REJECT_COMPANY_USER_REGISTRATION, payload);
    },
  },
};
</script>
